import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddresses,
  reset
} from "redux/admin/cryptoaddress/cryptoAddressSlice";
import Pagination from "components/Pagination";
import CryptoAddresses from "components/Panel/Admin/CryptoAddresses";
import { toast } from "react-toastify";

const CryptoAddressesContainer = () => {
  const dispatch = useDispatch();
  const {
    addressList,
    isCollectSuccess,
    isDeleteSuccess,
    isCreateSuccess,
    isError,           // For GET failures
    isCollectError,
    isDeleteError,
    isCreateError,
    message
  } = useSelector((state) => state.adminCryptoAddress);

  const { data, page, pages, per_page } = addressList;

  useEffect(() => {
    dispatch(getAddresses({ page: 1, per_page: 15 }));
  }, [dispatch]);

  useEffect(() => {
    // Success handling
    if (isCollectSuccess) {
      toast.success("Collect operation successful!");
      dispatch(reset());
      dispatch(getAddresses({ page: 1, per_page: 15 }));
    }
    if (isDeleteSuccess) {
      toast.success("Address deleted!");
      dispatch(reset());
      dispatch(getAddresses({ page: 1, per_page: 15 }));
    }
    if (isCreateSuccess) {
      toast.success("Address created!");
      dispatch(reset());
      dispatch(getAddresses({ page: 1, per_page: 15 }));
    }

    // Error handling
    if (isError) {
      toast.error(message || "Failed to fetch addresses!");
      dispatch(reset());
    }
    if (isCollectError) {
      toast.error(message || "Failed to collect address!");
      dispatch(reset());
    }
    if (isDeleteError) {
      toast.error(message || "Failed to delete address!");
      dispatch(reset());
    }
    if (isCreateError) {
      toast.error(message || "Failed to create address!");
      dispatch(reset());
    }
  }, [
    dispatch,
    isCollectSuccess,
    isDeleteSuccess,
    isCreateSuccess,
    isError,
    isCollectError,
    isDeleteError,
    isCreateError,
    message
  ]);

  const handlePageChange = (newPage) => {
    dispatch(getAddresses({ page: newPage, per_page: 15 }));
  };

  return (
    <div className="bg-black min-h-screen p-4">
      <h2 className="text-xl font-semibold text-white-100 mb-4">
        USDT (TRC20) Addresses
      </h2>

      <CryptoAddresses data={data} />

      <Pagination
        currentPage={page || 1}
        totalPages={pages || 1}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default CryptoAddressesContainer;
