import instance from "api/instance";

// Adjust endpoint paths to match your backend
// e.g. GET /cryptoaddress, POST /cryptoaddress, PATCH /cryptoaddress, DELETE /cryptoaddress/:id

const getCryptoAddresses = async (params) => {
  // example: GET /cryptoaddress? page=1, per_page=15
  const response = await instance.get("/admin/cryptoaddress", { params });
  if (response.status === 200) {
    return response.data;
  }
  return Promise.reject(response.data);
};

const createCryptoAddress = async (data) => {
  // example: POST /cryptoaddress body: { payment_key, namesurname }
  const response = await instance.post("/admin/cryptoaddress", data);
  if (response.status === 201) {
    return response.data;
  }
  return Promise.reject(response.data);
};

const collectCryptoAddress = async (id) => {
  // example: PATCH /cryptoaddress with { action:'collect', id }
  const response = await instance.patch("/admin/cryptoaddress", { action: "collect", id });
  if (response.status === 200) {
    return response.data;
  }
  return Promise.reject(response.data);
};

const deleteCryptoAddress = async (id) => {
  // example: DELETE /cryptoaddress/:id
  const response = await instance.delete(`/admin/cryptoaddress/${id}`);
  if (response.status === 200) {
    return response.data;
  }
  return Promise.reject(response.data);
};

const cryptoAddressService = {
  getCryptoAddresses,
  createCryptoAddress,
  collectCryptoAddress,
  deleteCryptoAddress,
};

export default cryptoAddressService;
