import React from 'react'
import CryptoAddressContainer from 'containers/panel/admin/cryptoaddress-container'

const CryptoAddressPage = () => {
  return (
    <CryptoAddressContainer />
  )
}

export default CryptoAddressPage
