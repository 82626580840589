import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cryptoAddressService from "./cryptoAddressService";

// LIST addresses
export const getAddresses = createAsyncThunk(
  "admin/cryptoAddress/list",
  async (params, { rejectWithValue }) => {
    try {
      return await cryptoAddressService.getCryptoAddresses(params);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

// CREATE address
export const createAddress = createAsyncThunk(
  "admin/cryptoAddress/create",
  async (formData, { rejectWithValue }) => {
    try {
      return await cryptoAddressService.createCryptoAddress(formData);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

// COLLECT (send funds)
export const collectAddress = createAsyncThunk(
  "admin/cryptoAddress/collect",
  async (id, { rejectWithValue }) => {
    try {
      return await cryptoAddressService.collectCryptoAddress(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

// DELETE address
export const deleteAddress = createAsyncThunk(
  "admin/cryptoAddress/delete",
  async (id, { rejectWithValue }) => {
    try {
      return await cryptoAddressService.deleteCryptoAddress(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  addressList: {
    data: [],
    total: 0,
    pages: 1,
    page: 1,
    per_page: 15
  },
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",

  isCreateSuccess: false,
  isCreateError: false,
  isCollectSuccess: false,
  isCollectError: false,
  isDeleteSuccess: false,
  isDeleteError: false,
};

export const cryptoAddressSlice = createSlice({
  name: "adminCryptoAddress",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.isCreateSuccess = false;
      state.isCreateError = false;
      state.isCollectSuccess = false;
      state.isCollectError = false;
      state.isDeleteSuccess = false;
      state.isDeleteError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // GET Addresses
      .addCase(getAddresses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddresses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // assume the endpoint returns an array or object with pagination
        // if your backend returns a simple array, adjust accordingly
        // e.g. action.payload = [ {...}, {...} ] or
        // e.g. action.payload = { data: [...], total: X, pages: Y, page: Z}
        if (Array.isArray(action.payload)) {
          // no pagination
          state.addressList.data = action.payload;
        } else {
          state.addressList.data = action.payload; 
        }
      })
      .addCase(getAddresses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      
      // CREATE Address
      .addCase(createAddress.fulfilled, (state) => {
        state.isCreateSuccess = true;
      })
      .addCase(createAddress.rejected, (state, action) => {
        state.isCreateError = true;
        state.message = action.payload;
      })

      // COLLECT Address
      .addCase(collectAddress.fulfilled, (state) => {
        state.isCollectSuccess = true;
      })
      .addCase(collectAddress.rejected, (state, action) => {
        state.isCollectError = true;
        state.message = action.payload;
      })

      // DELETE Address
      .addCase(deleteAddress.fulfilled, (state) => {
        state.isDeleteSuccess = true;
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.isDeleteError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = cryptoAddressSlice.actions;
export default cryptoAddressSlice.reducer;
