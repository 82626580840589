import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { collectAddress, deleteAddress, createAddress } from "redux/admin/cryptoaddress/cryptoAddressSlice";
import { MdOutlineDone, MdClear } from "react-icons/md";

const CryptoAddresses = ({ data }) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [paymentKey, setPaymentKey] = useState("");
  const [namesurname, setNameSurname] = useState("");

  const handleCollect = (id) => {
    dispatch(collectAddress(id));
  };

  const handleDelete = (id) => {
    dispatch(deleteAddress(id));
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleCreateAddress = (e) => {
    e.preventDefault();
    // Dispatch createAddress action
    dispatch(createAddress({ payment_key: paymentKey, namesurname }));
    // Optionally clear form fields & hide form
    setPaymentKey("");
    setNameSurname("");
    setShowForm(false);
  };

  return (
    <div className="overflow-x-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-white-100">Crypto Addresses</h3>
        <button
          onClick={toggleForm}
          className="bg-primary text-white px-4 py-2 rounded-md font-semibold"
        >
          Yeni Adres Oluştur
        </button>
      </div>

      {showForm && (
        <form onSubmit={handleCreateAddress} className="bg-gray-800 p-4 mb-4 rounded-md">
          <div className="mb-2">
            <label className="block text-white-100 font-medium mb-1" htmlFor="payment_key">
              Payment Key
            </label>
            <input
              id="payment_key"
              type="text"
              value={paymentKey}
              onChange={(e) => setPaymentKey(e.target.value)}
              className="w-full p-2 rounded-md"
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-white-100 font-medium mb-1" htmlFor="namesurname">
              Ad Soyad
            </label>
            <input
              id="namesurname"
              type="text"
              value={namesurname}
              onChange={(e) => setNameSurname(e.target.value)}
              className="w-full p-2 rounded-md"
              required
            />
          </div>
          <button
            type="submit"
            className="bg-green-600 text-white px-4 py-2 mt-2 rounded-md font-semibold"
          >
            Submit
          </button>
        </form>
      )}

      <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-white-100">ID</th>
            <th className="px-4 py-2 text-left text-white-100">Payment Key</th>
            <th className="px-4 py-2 text-left text-white-100">Ad Soyad</th>
            <th className="px-4 py-2 text-left text-white-100">Adres</th>
            <th className="px-4 py-2 text-left text-white-100">Coin Türü</th>
            <th className="px-4 py-2 text-left text-white-100">Bakiye</th>
            <th className="px-4 py-2 text-left text-white-100">İşlemler</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data?.map((addr) => (
            <tr key={addr.id}>
              <td className="px-4 py-2 text-white-100">{addr.id}</td>
              <td className="px-4 py-2 text-white-100">{addr.payment_key}</td>
              <td className="px-4 py-2 text-white-100">{addr.namesurname}</td>
              <td className="px-4 py-2 text-white-100">{addr.address}</td>
              <td className="px-4 py-2 text-white-100">{addr.currency}</td>
              <td className="px-4 py-2 text-white-100">{addr.balance}</td>
              <td className="px-4 py-2 text-white-100">
                <div className="flex gap-2">
                  {/* Collect button */}
                  <button
                    onClick={() => handleCollect(addr.id)}
                    className="bg-primary text-white rounded-md px-2 py-1 flex items-center font-semibold"
                  >
                    <MdOutlineDone className="mr-1" /> Ana Cüzdana Aktar
                  </button>
                  {/* Delete button */}
                  <button
                    onClick={() => handleDelete(addr.id)}
                    className="bg-red-500 text-white rounded-md px-2 py-1 flex items-center font-semibold"
                  >
                    <MdClear className="mr-1" /> Sil
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CryptoAddresses;
